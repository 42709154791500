.react-dual-listbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.react-dual-listbox * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.react-dual-listbox input:disabled,
.react-dual-listbox select:disabled {
  background: #eee;
  cursor: not-allowed;
}

.react-dual-listbox button,
.react-dual-listbox select {
  line-height: 1.42857;
  font-family: inherit;
}

.rdl-list-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-item-align: stretch;
      align-self: stretch;
}

.rdl-filter,
.rdl-control {
  display: block;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 8px 12px;
  width: 100%;
  color: #333;
  font-size: 10px;
}

.rdl-filter {
  margin-bottom: 10px;
}

.rdl-control-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.rdl-control-label {
  padding: 2px 4px;
}

.rdl-control-label.rdl-sr-only {
  position: absolute;
  clip: rect(0 0 0 0);
}

.rdl-control {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
}

.rdl-control optgroup {
  font: inherit;
  font-weight: 700;
}

.rdl-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 10px;
}

.rdl-has-header .rdl-actions {
  padding-top: 31px;
}

.rdl-has-filter .rdl-actions {
  padding-top: 44px;
}

.rdl-has-header.rdl-has-filter .rdl-actions {
  padding-top: 75px;
}

.rdl-actions-right,
.rdl-actions-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.rdl-actions-right {
  margin-bottom: 10px;
}

.rdl-move {
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  color: #333;
  font-size: 8px;
}

.rdl-move:active:not(:disabled), .rdl-move:focus:not(:disabled) {
  border-color: #8c8c8c;
  background: #e6e6e6;
}

.rdl-move:focus:not(:disabled) {
  outline: thin dotted;
  outline-offset: -2px;
}

.rdl-move:hover:not(:disabled) {
  border-color: #adadad;
  background: #e6e6e6;
}

.rdl-move:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.rdl-move:last-child {
  margin-bottom: 0;
}

.rdl-move i {
  margin: 0 -1px;
}

.rdl-align-top .rdl-available {
  margin-right: 10px;
}

.rdl-align-top .rdl-selected {
  margin-left: 10px;
}

.rdl-align-top .rdl-control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rdl-align-top .rdl-actions-left,
.rdl-align-top .rdl-actions-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0;
}

.rdl-align-top .rdl-move {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 50%;
          flex: 0 1 50%;
}

.rdl-align-top .rdl-move:first-child {
  margin-bottom: 0;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rdl-align-top .rdl-move:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
